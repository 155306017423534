/* eslint-disable @nx/enforce-module-boundaries */
'use client';
import { Box, HStack, Link, Text, VStack } from '@mybridge/ui';
import { forwardRef } from 'react';
export const MainCopyrightsText = forwardRef(({alignment, ...props }, ref) => {
  return (
    <>
      <VStack
        textAlign="center"
        fontSize="xs"
        color="gray.500"
        {...props}
        alignItems={alignment}
        mt={3}
      >
        <Box>
          <Text display="inline" as={Link} href="/terms-of-use">
            Terms & Conditions
          </Text>
          <Text display="inline">{' · '}</Text>
          <Text display="inline" as={Link} href="/privacy-policy">
            Privacy Policy
          </Text>
        </Box>
        <Box mt="-6px">
          <Text display="inline" as={Link} href="/business">
            Business
          </Text>
          <Text display="inline">{' · '}</Text>
          <Text display="inline" as={Link} href="/support">
            Help & Support
          </Text>
        </Box>
        <Text> myBridge Corporation © {new Date().getFullYear()}</Text>
      </VStack>
    </>
  );
});
