/* eslint-disable @nx/enforce-module-boundaries */
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Box,
  HStack,
  RadioGroup,
} from '@mybridge/ui';
import { useInfiniteQuery } from '@tanstack/react-query';
import { forwardRef, useEffect, useRef, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FilterPopover } from 'v4/components/filter-popover';
import {
  getGlobalCompanies,
  searchAll,
} from 'v4/store/actions/global.data.actions';
import { flattenQueryResults, getNextPageFromURL } from 'v4/utils/utils';
import _ from 'lodash';
import { ArrowDown, CloseIcon } from '@mybridge/icons';
import { CustomRadioButton } from 'v4/components/custom-radio-checkbx';
import { useRadioGroup } from '@chakra-ui/react';
import { SearchContext } from '../../../snippets/search/context';
import styles from '../../filter-popover/filter-popover.module.scss';
export const SearchFilterFromCompany = forwardRef(
  (
    {
      name = 'from_company',
      title = 'From Company',
      placeholder = "Add a company's name",
      onChange,
      value,
      defaultValue,
      defaultArray,
      filterFor,
      ...props
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const tid = useRef(-1);
    const [search, setSearch] = useState();
    const [allData, setAllData] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const [popperOpen, setPopperOpen] = useState(false);
    const { resetFilters, setResetFilters } = useContext(SearchContext);
    const { data, isFetching, refetch, remove } = useInfiniteQuery({
      queryKey: [name, search],
      queryFn: async ({ queryKey }) => {
        try {
          const resp = await dispatch(
            getGlobalCompanies({
              category: 'pages',
              search: queryKey?.[1],
              isNoToken: true,
            })
          );
          return resp?.payload ?? {};
        } catch (e) {
          console.error(e);
        }
      },
      getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
      refetchOnWindowFocus: false,
      enabled: false,
    });

    useEffect(() => {
      if (data?.pages && search?.length) {
        setAllData(flattenQueryResults(data));
      } else {
        if (defaultArray?.length) {
          if (filterFor === 'people') {
            setAllData(_.map(_.uniq(_.compact(defaultArray))));
          } else {
            setAllData(_.filter(defaultArray, (item) => item?.name !== ''));
          }
        } else {
          setAllData([]);
        }
      }
    }, [data, search]);

    useEffect(() => {
      if (search) {
        refetch?.();
      }
    }, [search]);

    const { handleSubmit, control } = useForm();

    const handleSearch = (e) => {
      clearTimeout(tid.current);
      tid.current = setTimeout(() => {
        setSearch(e?.target?.value);
      }, 300);
    };

    const onSubmit = (form) => {
      const data_ = _.find(allData, function(o){return o.id == form});
      onChange?.({ [name]: form });
      setSelectedValue(data_ ? data_?.name : form);
      setPopperOpen(false);
    };

    useEffect(() => {
      if (defaultArray?.length) {
        if (filterFor === 'people') {
          // setAllData(_.filter(defaultArray, (item) => item !== ''));
          setAllData(_.map(_.uniq(_.compact(defaultArray))));
        } else {
          setAllData(_.filter(defaultArray, (item) => item?.name !== ''));
        }
      }
    }, [defaultArray]);

    useEffect(() => {
      selectedValue ? setResetFilters(true) : setResetFilters(false)
    }, [selectedValue])

    useEffect(() => {
      if(!resetFilters){
        setSelectedValue();
        setSearch();
      }
    }, [resetFilters]);

    const { getRadioProps, getRootProps } = useRadioGroup({
      onChange: onSubmit,
    });

    return (
      <>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <FilterPopover
          trigger={
            <Box w="100%">
              <Button
                  variant="transparent"
                  fontSize="15px"
                  color="brandGray.750"
                  rightIcon={selectedValue ? '' : ""}
                  onClick={(e) => setPopperOpen((prev) => !prev)}
                  justifyContent="space-between"
                  w="100%"
                >
                   {selectedValue && resetFilters ? selectedValue : title}
                    {selectedValue && resetFilters ? <CloseIcon width="15" height="15" color="#3D5A80" cursor="pointer" onClick={() => onSubmit(null)} /> : <ArrowDown />}
                </Button>
            </Box>
          }
          isFetching={isFetching}
          onPopClose={(e) => setPopperOpen(e)}
        >
          {/* <Controller
              render={({ field }) => (
                <CheckboxGroup {...field}>
                  <Stack maxH="48" overflowY="hidden">
                    {allData?.map?.((v, vIndex) => (
                      <Checkbox py={1} key={vIndex} value={v?.id ? v?.id : v + ''}>
                        {v?.name ? v?.name : v}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              )}
              control={control}
              name={name}
            /> */}
            <InputGroup pl={6}>
                  <Input
                    placeholder={placeholder}
                    defaultValue={search}
                    onChange={handleSearch}
                    variant="transparent"
                    border="none"
                    h="24px"
                  />
                  {/* {isFetching ? (
                    ''
                  ) : (
                    <InputRightElement>
                      <ArrowDown />
                    </InputRightElement>
                  )} */}
                </InputGroup>
          {isFetching ? (
            <HStack w="100%" justifyContent="center">
              <Spinner />
            </HStack>
          ) : (
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack maxH="48" overflowY="hidden" className={styles.filterList}>
                    {allData?.map?.((v, vIndex) => (
                      <CustomRadioButton
                        key={vIndex}
                        type="checkbox"
                        justifyContent="flex-start"
                        {...getRadioProps({ value: v?.id ? v?.id : v + '' })}
                      >
                        {v?.name ? v?.name : v}
                      </CustomRadioButton>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
              control={control}
              name={name}
            />
          )}
        </FilterPopover>
        {/* </form> */}
      </>
    );
  }
);
